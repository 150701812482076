import React, { useEffect } from "react";
import gsap from "gsap";
import styled from "@emotion/styled";
import { PropTypes } from "prop-types";
import Div100vh from "react-div-100vh";
import { colors } from "../../configs/_configSite";

const Pagination = styled(Div100vh)`
  z-index: 1001;
  position: fixed;
  right: 0;
  top: 0;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
`;

const Progress = styled.section`
  position: relative;
  right: 0;
  width: 100%;
  height: 0vh;
  background: ${colors.gold};
`;

const SwiperPagination = ({ progress, duration, ease }) => {
  useEffect(() => {
    gsap.to(".home-slide-pagination-progress", duration, {
      ease,
      css: { height: `${progress * 100}vh` }
    });
  }, [progress]);

  return (
    <Pagination>
      <Progress className="home-slide-pagination-progress" />
    </Pagination>
  );
};

SwiperPagination.defaultProps = {
  progress: 0,
  duration: 0,
  ease: "linear"
};

SwiperPagination.propTypes = {
  progress: PropTypes.number,
  duration: PropTypes.number,
  ease: PropTypes.string
};

export default SwiperPagination;
