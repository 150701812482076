/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const VideoPLayerFullScreen = ({ video }) => {
  return (
    <Container>
      <video
        loop
        autoPlay
        muted
        css={css`
          position: absolute;
          height: 110%;
          margin: 0 auto;
          left: -50%;
          right: -50%;
        `}
      >
        <source src={video} type="video/mp4" />
        <source src={video} type="video/mov" />
      </video>
    </Container>
  );
};

export default VideoPLayerFullScreen;
