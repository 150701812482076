/* eslint-disable no-unused-expressions */
import React, { createContext, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { useStateRef } from "../utilities/hooks";

export const ContextEntryUI = createContext();

export const ProviderEntryUI = ({ children }) => {
  const [entryClosed, setEntryClosed, entryClosedRef] = useStateRef(true);
  const readyToSlideDown = useRef(false);
  const slidesAbove = useRef(false);

  if (typeof window !== "undefined") {
    useEffect(() => {
      if (window.location.pathname !== "/") {
        readyToSlideDown.current = true;
        setEntryClosed(false);
      } else {
        readyToSlideDown.current = false;
      }
    }, [window.location.pathname]);
  }

  return (
    <ContextEntryUI.Provider
      value={{
        readyToSlideDown,
        slidesAbove,
        entryClosed,
        setEntryClosed,
        entryClosedRef
      }}
    >
      {children}
    </ContextEntryUI.Provider>
  );
};

ProviderEntryUI.defaultProps = {
  children: <></>
};
ProviderEntryUI.propTypes = {
  children: PropTypes.node
};
